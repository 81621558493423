import React from "react"
import { Helmet } from "react-helmet"

import CssReset from "../theme/styles/cssReset"
import Header from "../components/molocules/header"
import FooterContainer from "../components/molocules/footer"
import Alert from "../components/elements/alert/alert"
import { Link } from "gatsby"
import ChatButton from "../components/elements/chatButton"

const Layout = ({
  children,
  isSubMenuOpen,
  openAccountLink,
  forceRootMenu,
}) => {
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
          maximum-scale="1"
        />
      </Helmet>
      <CssReset />
      <div id="main-content">
      {/*<Alert type="Info" enabled>
          We’re excited to announce that we are moving our email addresses over to our new domain name, tcbga.bank, from our old theclaxtonbank.com domain, to provide you with an even more secure 
          banking experience. This transition will be phased over the coming weeks, but after this change is complete, all emails will be forwarded from our theclaxtonbank.com addresses to our new 
          tcbga.bank addresses. Click <Link to="/domain-security">here</Link> to learn more about the changes and about .BANK top level domains. 
  </Alert>
      <Alert type="Info" enabled>
        <b>Fraud Alert:</b> TCB and other area banks have seen a huge uptick in
        phishing texts over the last 24 hours. If you receive a call or text
        message from someone claiming to be from The Claxton Bank asking to
        click on a link or provide any sensitive information, such as name,
        address, social security number, or digital banking username or
        password, please hang up immediately and call TCB 912-739-3322. WE WILL
        NEVER CALL YOU AND ASK FOR PERSONAL OR SENSITIVE INFORMATION. If you
        feel like you have inadvertently given out or shared your personal
        information, please contact us immediately. Thanks. – The Claxton Bank.
      </Alert>*/}

      <Header
        isSubMenuOpen={isSubMenuOpen}
        openAccountLink={openAccountLink}
        forceRootMenu={forceRootMenu}
      />
      {children}
      <ChatButton />
      <FooterContainer />
      </div>
    </>
  )
}

export default Layout
